import { NavigateNext, NavigateBefore } from '@mui/icons-material'
import { IconButton } from '~/shared/ui/Buttons'
import { CustomPagination, CustomPaginationPage } from './styled'

type SimplePaginationProps = {
  isRowsCount: boolean
  page: number
  onPageChange: (page: number) => void
}

export function SimplePagination({
  isRowsCount,
  page,
  onPageChange,
}: SimplePaginationProps) {
  return (
    <CustomPagination>
      <IconButton onClick={() => onPageChange(page - 1)} disabled={page === 1}>
        <NavigateBefore />
      </IconButton>

      <CustomPaginationPage>{page || 1}</CustomPaginationPage>

      <IconButton
        disabled={!isRowsCount}
        onClick={() => onPageChange(page + 1)}
      >
        <NavigateNext />
      </IconButton>
    </CustomPagination>
  )
}
