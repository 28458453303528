import { zodResolver } from '@hookform/resolvers/zod'
import { Grid } from '@mui/joy'
import { useForm } from 'react-hook-form'
import { Region } from '~/shared/api'
import { Button } from '~/shared/ui/Buttons'
import {
  AsyncAutocompleteInput,
  FormProvider,
  TextInput,
} from '~/shared/ui/Form'
import { PlusIcon } from '~/shared/ui/Icons'
import { formSchema, FormValues } from './model'

type FormProps = {
  onSuccess: (formValues: FormValues) => void
  isSubmitting: boolean
  excludeRegions: string
}

export function Form({ onSuccess, isSubmitting, excludeRegions }: FormProps) {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  })

  return (
    <FormProvider form={form} onSuccess={onSuccess}>
      <Grid container spacing={2.5}>
        <Grid xs={12}>
          <AsyncAutocompleteInput
            label='Регион'
            placeholder='Выберите регион'
            name='regionId'
            fetchOptions={(search) =>
              Region.fetchOptionsByExclude(search, excludeRegions)
            }
            readOnly={isSubmitting}
            queryKey={[excludeRegions]}
          />
        </Grid>
        <Grid xs={12}>
          <TextInput
            label='Диспетчер'
            name='dispatcher'
            placeholder='Введите ФИО диспетчера'
            readOnly={isSubmitting}
          />
        </Grid>
        <Grid xs={12}>
          <TextInput
            label='Контроллёр'
            name='controller'
            placeholder='Введите ФИО контроллера'
            readOnly={isSubmitting}
          />
        </Grid>
        <Grid xs={12}>
          <TextInput
            label='Медик'
            name='doctor'
            placeholder='Введите ФИО медика'
            readOnly={isSubmitting}
          />
        </Grid>
      </Grid>
      <br />
      <Button
        loading={isSubmitting}
        startDecorator={<PlusIcon />}
        fullWidth
        type='submit'
      >
        Добавить
      </Button>
    </FormProvider>
  )
}
