import * as z from 'zod'
import { CarLicensee } from '~/shared/api'

export const formSchema = CarLicensee.schema.pick({
  name: true,
  inn: true,
  ogrn: true,
  okpo: true,
  phoneNumber: true,
  legalAddress: true,
  freighterPermitNumber: true,
  freighterPermitExpirationDate: true,
})

export type TypeFormSchema = z.infer<typeof formSchema>

export type FormValues = TypeFormSchema
