import { z } from 'zod'
import { CarLicenseeEmployee } from '~/shared/api'
import {
  dateOptionalSchema,
  dateSchema,
  innRequiredSchema,
  ogrnRequiredSchema,
  okpoRequiredSchema,
} from '~/shared/lib/schemas'
import { ApiModel, ToManyRelation } from './core'

const attributeSchema = z.object({
  name: z.string().trim().min(1, 'Обязательное поле'),
  ogrn: ogrnRequiredSchema,
  inn: innRequiredSchema,
  okpo: okpoRequiredSchema.optional(),
  phoneNumber: z.string().trim().min(1, 'Обязательное поле'),
  legalAddress: z.string().trim().min(1, 'Обязательное поле'),
  freighterPermitNumber: z.string().trim(),
  freighterPermitExpirationDate: dateOptionalSchema.transform(
    (arg) => arg || null,
  ),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)

export type CarLicenseeAttributes = z.infer<typeof schema>

export class CarLicensee extends ApiModel<typeof schema> {
  static jsonApiType = 'car-licensees'

  static schema = schema

  getName(): string {
    return this.getAttribute('name')
  }

  carLicenseeEmployees(): ToManyRelation<CarLicenseeEmployee, this> {
    return this.hasMany(CarLicenseeEmployee)
  }
  getCarLicenseeEmployees(): CarLicenseeEmployee[] {
    return this.getRelation('carLicenseeEmployees')
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getName(),
    }
  }

  static async fetchOptions(search: string) {
    const response = await CarLicensee.where('name', search).get(1)
    return response.getData().map((o) => o.getOption())
  }
}
