import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { rentalContractCreateModel } from '~/features/RentalContracts/CreateRentalContract'
import { driverModel } from '~/entities/Driver'
import { rentalContractModel } from '~/entities/RentalContract'
import { RentalContract } from '~/shared/api'
import { RentalContractStatusEnum } from '~/shared/config/enums'
import { isString } from '~/shared/lib/utils'

export const domain = createDomain(
  'widgets.driver.rentalContractControls.created',
)

export const GetRentalContractActiveGate = createGate()

export const getRentalContractActive = domain.createEvent()
export const getRentalContractActiveFx = domain.createEffect<
  UniqueId,
  RentalContract | null
>({
  async handler(driverId: UniqueId) {
    const rentalContractActive = await fetchRentalContactActive(driverId)
    return rentalContractActive || null
  },
})

export const setActiveRentalContract =
  domain.createEvent<RentalContract | null>()
export const resetActiveRentalContract = domain.createEvent()

export const $activeRentalContract = domain
  .createStore<RentalContract | null>(null)
  .on(setActiveRentalContract, (_, rentalContract) => rentalContract)
  .on(resetActiveRentalContract, () => null)

export const $getRentalContractActiveIsLoading = domain
  .createStore(true)
  .on(
    [getRentalContractActiveFx.doneData, getRentalContractActiveFx.fail],
    () => false,
  )
  .on(GetRentalContractActiveGate.close, () => true)

sample({
  clock: GetRentalContractActiveGate.open,
  target: getRentalContractActive,
})

sample({
  clock: GetRentalContractActiveGate.close,
  target: resetActiveRentalContract,
})

sample({
  clock: getRentalContractActiveFx.doneData,
  target: setActiveRentalContract,
})

async function fetchRentalContactActive(driverId: UniqueId) {
  const response = await RentalContract.where(
    'status',
    [RentalContractStatusEnum.ACTIVE, RentalContractStatusEnum.SIGNING].join(
      ',',
    ),
  )
    .where('driverId', driverId)
    .first()
  return response.getData()
}

sample({
  clock: getRentalContractActive,
  source: driverModel.$id,
  filter: isString,
  target: getRentalContractActiveFx,
})

sample({
  clock: [
    rentalContractCreateModel.createFx.doneData,
    rentalContractModel.activeRentalContractFx.doneData,
  ],
  target: getRentalContractActive,
})

sample({
  clock: [
    rentalContractModel.terminateRentalContractFx.doneData,
    rentalContractModel.canceledRentalContractFx.doneData,
  ],
  target: resetActiveRentalContract,
})
