import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { debounce } from 'patronum'
import * as z from 'zod'
import { Car, LocationOption } from '~/shared/api'
import { CAR_VIN_LENGTH, Option, OptionEnum } from '~/shared/config/constants'
import {
  CarPropertyTypeEnum,
  FuelTypeEnum,
  TransmissionEnum,
} from '~/shared/config/enums'

export const formSchema = Car.schema.pick({
  vin: true,
  transmission: true,
  engineNumber: true,
  engineSize: true,
  enginePower: true,
  fuelType: true,
  manufactureYear: true,
  bodyNumber: true,
  startExploitationDate: true,
  estimatedCost: true,
  modelId: true,
  subdivisionId: true,
  colorId: true,
  vehicleCategoryId: true,
  carRentalId: true,
  regionId: true,
  locationId: true,
  aggregatorAccountOption: true,
  isLeasingFullyPaid: true,
  isPromotion: true,
  isPreTotaled: true,
  propertyType: true,
})

type SchemaType = z.infer<typeof formSchema>

export type FormValues = Omit<
  SchemaType,
  | 'modelId'
  | 'colorId'
  | 'subdivisionId'
  | 'vehicleCategoryId'
  | 'carRentalId'
  | 'regionId'
  | 'locationId'
  | 'aggregatorAccountOption'
  | 'transmission'
  | 'fuelType'
  | 'propertyType'
> & {
  modelId: UniqueId | Option | null
  colorId: UniqueId | Option | null
  subdivisionId: UniqueId | Option | null
  vehicleCategoryId: UniqueId | Option | null
  carRentalId?: UniqueId | Option | null
  regionId?: UniqueId | Option | null
  locationId?: UniqueId | LocationOption | null
  aggregatorAccountOption: UniqueId | Option | null
  plateNumber?: string | null
  transmission?: TransmissionEnum | OptionEnum<TransmissionEnum> | null
  fuelType?: FuelTypeEnum | OptionEnum<FuelTypeEnum> | null
  propertyType?: CarPropertyTypeEnum | OptionEnum<CarPropertyTypeEnum> | null
}

export const carSchema = Car.schema.pick({ vin: true })
export type CheckHasCarParams = z.infer<typeof carSchema>

export const CarFormGate = createGate<{ carId?: UniqueId | null }>()
export const domain = createDomain('car.form')

const $carId = domain
  .createStore<UniqueId | null | undefined>(null)
  .on(CarFormGate.state, (_, { carId }) => carId)

async function requestByFilters({ vin }: Partial<CheckHasCarParams>) {
  let builder = Car.orderBy('-createdAt')

  if (vin) builder = builder.where('vin', vin)

  const response = await builder.first()
  return response.getData() as Car
}

export const requestByVinFx = domain.createEffect<
  CheckHasCarParams['vin'],
  Car
>({
  async handler(vin) {
    return await requestByFilters({ vin })
  },
})

// Vin
export const checkHasCarByVin =
  domain.createEvent<Partial<CheckHasCarParams['vin']>>()
const debouncedCheckHasCarByVin = debounce({
  source: checkHasCarByVin,
  timeout: 200,
})

const toggleIsHasCarWithVin = domain.createEvent<boolean>()
export const $isHasCarWithVin = domain
  .createStore<boolean>(false)
  .on(toggleIsHasCarWithVin, (_, value) => value)

sample({
  clock: debouncedCheckHasCarByVin,
  filter: (vin) => vin.length === CAR_VIN_LENGTH,
  target: requestByVinFx,
})

sample({
  clock: checkHasCarByVin,
  source: $isHasCarWithVin,
  filter: (isHas, vin) => isHas && vin?.length !== CAR_VIN_LENGTH,
  fn: () => false,
  target: toggleIsHasCarWithVin,
})

sample({
  clock: requestByVinFx.doneData,
  source: $carId,
  fn: (carId, car) => Boolean(car && car.getApiId() !== carId),
  target: toggleIsHasCarWithVin,
})

// Gate
sample({
  clock: CarFormGate.close,
  fn: () => false,
  target: [toggleIsHasCarWithVin],
})
