import { useStore, useStoreMap } from 'effector-react'
import { Form, carLicenseeModel } from '~/entities/Organizations/CarLicensee'
import { usePermissions } from '~/entities/viewer'
import { QuickNavigation } from '~/shared/ui/QuickNavigation'
import { formSubmitted, $carLicensee, carLicenseeUpdateFx } from './model'

const quickNavigationOptions = [
  { label: 'Реквизиты', anchor: 'requisites' },
  { label: 'Реестр', anchor: 'registry' },
  { label: 'Контакты', anchor: 'contacts' },
]

export function EditCarLicensee() {
  const { canCarOrganizationsUpdate } = usePermissions()

  const isLoading = useStore(carLicenseeModel.requestFx.pending)
  const isSaving = useStore(carLicenseeUpdateFx.pending)

  const defaultValues = useStoreMap($carLicensee, (carLicensee) => ({
    ...carLicensee?.getAttributes(),
  }))

  return (
    <>
      <Form
        isLoading={isLoading}
        defaultValues={defaultValues}
        onSuccess={formSubmitted}
        isSubmitting={isSaving}
        canEdit={canCarOrganizationsUpdate}
      />

      <QuickNavigation options={quickNavigationOptions} />
    </>
  )
}
