import { zodResolver } from '@hookform/resolvers/zod'
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  Grid,
  Stack,
} from '@mui/joy'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '~/shared/ui/Buttons'
import { FormProvider, TextInput } from '~/shared/ui/Form'
import {
  getTextInputProps,
  mappedDefaultValues,
  sortEmployees,
} from './helpers'
import { EmployeesForm, formSchema, FormValues } from './model'

export type EditFormProps = {
  employees: EmployeesForm
  onSuccess: (formValues: FormValues) => void
  isSubmitting: boolean
  canEdit: boolean
}

export function EditForm({
  canEdit,
  isSubmitting,
  employees,
  onSuccess,
}: EditFormProps) {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const disabled = !isEditing || isSubmitting

  const defaultValues = useMemo(
    () => mappedDefaultValues(employees),
    [employees],
  )

  const form = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(formSchema),
  })

  const { reset } = form

  const handleButtonsShow = () => {
    if (!canEdit) return null
    setIsEditing(true)
  }

  const handleButtonsHidden = () => {
    setIsEditing(false)
  }

  const handleCancel = () => {
    handleButtonsHidden()
  }

  useEffect(() => {
    if (defaultValues) reset(defaultValues)
    // eslint-disable-next-line
  }, [isEditing])

  useEffect(() => {
    if (!isSubmitting) handleButtonsHidden()
  }, [isSubmitting])

  const sortedEmployees = useMemo(() => sortEmployees(employees), [employees])

  return (
    <FormProvider form={form} onSuccess={onSuccess}>
      <Grid container spacing={2.5} onDoubleClick={handleButtonsShow}>
        {sortedEmployees.map((employees) => (
          <Grid xs={4} key={employees.id}>
            <TextInput
              name={employees.id}
              readOnly={disabled}
              {...getTextInputProps(employees.employeeType)}
            />
          </Grid>
        ))}

        <Grid xs={4}>
          <AccordionGroup>
            <Accordion expanded={isEditing} sx={{ minHeight: 0, padding: 0 }}>
              <AccordionDetails>
                <Stack direction='row' spacing={2}>
                  <Button
                    variant='red'
                    onClick={handleCancel}
                    disabled={isSubmitting}
                  >
                    Отменить
                  </Button>

                  <Button
                    variant='green'
                    type='submit'
                    loading={isSubmitting}
                    sx={{ marginRight: '24px' }}
                  >
                    Сохранить
                  </Button>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </AccordionGroup>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
