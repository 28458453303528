import { zodResolver } from '@hookform/resolvers/zod'
import { Box, CircularProgress, Grid, Typography } from '@mui/joy'
import { useStore } from 'effector-react'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { RentalService } from '~/shared/api'
import {
  daysEnumOptions,
  rentalContractWorkScheduleEnumOptions,
} from '~/shared/config/constants'
import { RentalContractWorkScheduleEnum } from '~/shared/config/enums'
import { getObject, isObject } from '~/shared/lib/utils'
import { Alert } from '~/shared/ui/Alerts'
import {
  AsyncAutocompleteInput,
  AutocompleteInput,
  DatePickerInput,
  DateTimePickerInput,
  FormButtons,
  formButtonsModel,
  FormProvider,
  NumericInput,
  Switch,
  TextInput,
} from '~/shared/ui/Form'
import { Container, ContainerTitle } from '~/shared/ui/Layout'
import {
  calculateRentPerDay,
  calculateRentPerDayFx,
  fetchCarOptions,
  formSchema,
  FormValues,
} from './model'

type FormProps = {
  isCreating?: boolean
  canEdit?: boolean
  isSubmitting?: boolean
  isLoading?: boolean
  onSuccess?: (formValues: FormValues) => void
  defaultValues?: Partial<FormValues>
  isBookedUntilVisible?: boolean
}

export function Form({
  isCreating,
  canEdit,
  isSubmitting,
  isLoading,
  defaultValues,
  onSuccess,
  isBookedUntilVisible,
}: FormProps) {
  const isEditing = useStore(formButtonsModel.$isEditing)
  const disabled = !isCreating || isSubmitting

  const isLoadingRentPerDay = useStore(calculateRentPerDayFx.pending)

  const form = useForm<FormValues>({
    resolver: zodResolver(
      !isCreating ? formSchema.omit({ bookedUntil: true }) : formSchema,
    ),
    defaultValues,
  })

  const { watch, setValue } = form

  const formCarOption = watch('carOption')
  const carOption = useMemo(
    () => (isObject(formCarOption) ? formCarOption : undefined),
    [formCarOption],
  )

  const workRuleTitle = defaultValues?.workRule || carOption?.workRule

  watch((data, { name, type }) => {
    if (type !== 'change') return

    if (name === 'carOption') {
      const carOption = isObject(data.carOption) ? data.carOption : undefined
      setValue('brand', carOption?.brand)
      setValue('model', carOption?.model)
      setValue('rental', carOption?.rental)
    }
  })

  const workScheduleOption = watch('workSchedule')
  const workSchedule = getObject(workScheduleOption)?.id

  const isDayOff =
    workSchedule === RentalContractWorkScheduleEnum.SIX_THROUGH_ONE

  const handleRentPerDaySet = (rentPerDay?: number) => {
    setValue('rentPerDay', rentPerDay)
  }

  useEffect(() => {
    if (!isCreating && !isEditing) return

    calculateRentPerDay({
      fn: handleRentPerDaySet,
      workRuleId: carOption?.workRuleId,
      workSchedule,
    })
    // eslint-disable-next-line
  }, [carOption?.workRuleId, workSchedule])

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues, isEditing])

  return (
    <FormProvider form={form} onSuccess={onSuccess} canEdit={canEdit}>
      <Container>
        <ContainerTitle>Основная информация</ContainerTitle>
        <Grid container spacing={2.5}>
          <Grid xs={6}>
            <TextInput
              label='Водитель'
              name='driverFullName'
              skeletonShow={isLoading}
              readOnly
            />
          </Grid>

          <Grid xs={6}>
            <TextInput
              label='Арендодатель'
              name='rental'
              placeholder='Арендодатель'
              skeletonShow={isLoading}
              readOnly
            />
          </Grid>

          <Grid xs={6}>
            <AsyncAutocompleteInput
              label='Автомобиль'
              placeholder='Выберите автомобиль'
              name='carOption'
              fetchOptions={fetchCarOptions}
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={3}>
            <TextInput
              label='Марка'
              placeholder='Марка'
              name='brand'
              readOnly
              skeletonShow={isLoading}
            />
          </Grid>
          <Grid xs={3}>
            <TextInput
              label='Модель'
              placeholder='Модель'
              name='model'
              readOnly
              skeletonShow={isLoading}
            />
          </Grid>

          <Grid xs={12}>
            <Alert variant='info' isLoading={isLoading} data-testid='workRule'>
              <Box flexDirection='column'>
                <Typography fontWeight={600} fontSize={18}>
                  Условие работы
                </Typography>
                <Typography fontSize={14}>
                  {workRuleTitle ||
                    'Выберите автомобиль для просмотра условия работы'}
                </Typography>
              </Box>
            </Alert>
          </Grid>
          <Grid xs={6}>
            <Switch
              label='Первый день аренды бесплатно'
              name='firstDayIsFree'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={6} />
          <Grid xs={6}>
            <AutocompleteInput
              label='График работы'
              placeholder='Выберите график работы'
              name='workSchedule'
              options={rentalContractWorkScheduleEnumOptions}
              readOnly={!isEditing && disabled}
              skeletonShow={isLoading}
            />
          </Grid>
          <Grid xs={6}>
            {isDayOff && (
              <AutocompleteInput
                label='Выходной день'
                placeholder='Выберите выходной день'
                name='dayOff'
                options={daysEnumOptions}
                readOnly={!isEditing && disabled}
                skeletonShow={isLoading}
              />
            )}
          </Grid>
          <Grid xs={6}>
            <NumericInput
              label='Стоимость аренды в сутки'
              placeholder='Стоимость аренды в сутки'
              name='rentPerDay'
              readOnly
              skeletonShow={isLoading}
              endDecorator={
                isLoadingRentPerDay ? <CircularProgress size='sm' /> : null
              }
            />
          </Grid>
          <Grid xs={6} />
          {defaultValues?.responsibleManager && (
            <Grid xs={6}>
              <TextInput
                label='Ответственный менеджер'
                name='responsibleManager'
                readOnly
                skeletonShow={isLoading}
              />
            </Grid>
          )}
          {defaultValues?.signedAt && (
            <Grid xs={3}>
              <TextInput
                label='Дата подписания'
                name='signedAt'
                readOnly
                skeletonShow={isLoading}
              />
            </Grid>
          )}
          {defaultValues?.terminationDate && (
            <Grid xs={3}>
              <DatePickerInput
                label='Дата расторжения'
                name='terminationDate'
                readOnly
                skeletonShow={isLoading}
              />
            </Grid>
          )}
        </Grid>
      </Container>

      <Container>
        <ContainerTitle>Дополнительные услуги</ContainerTitle>
        <Grid container spacing={2.5}>
          <Grid xs={12}>
            <AsyncAutocompleteInput
              placeholder='Выберите дополнительные услуги'
              name='rentalServicesOptions'
              fetchOptions={RentalService.fetchOptions}
              readOnly={!isEditing && disabled}
              skeletonShow={isLoading}
              multiple
            />
          </Grid>
          <Grid xs={12}>
            <Alert variant='warning'>
              Внимание: с водителя будет списываться плата за услуги.
              <br />
              Первое списание будет произведено сразу после подписания договора.
            </Alert>
          </Grid>
        </Grid>
      </Container>

      {isBookedUntilVisible && (
        <Container>
          <ContainerTitle>Время бронирования</ContainerTitle>
          <Grid container spacing={2.5}>
            <Grid xs={6}>
              <DateTimePickerInput
                label='Дата и время бронирования по Московскому часовому поясу'
                name='bookedUntil'
                placeholder='Выберите дату и время бронирования'
                readOnly={!isEditing && disabled}
                skeletonShow={isLoading}
                maxDays={3}
              />
            </Grid>
            <Grid xs={12}>
              <Alert variant='error'>
                Внимание: Договор будет аннулирован, если не будет подписан до
                окончания времени бронирования.
              </Alert>
            </Grid>
          </Grid>
        </Container>
      )}

      <FormButtons loading={isSubmitting} isCreating={isCreating} />
    </FormProvider>
  )
}
