export enum CheckKindEnum {
  GIBDD = 'GIBDD',
  FSSP = 'FSSP',
  YANDEX = 'YANDEX',
  KBM = 'KBM',
  SB = 'SB',
}

export enum CheckStatusEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  TIMEOUT_REACHED = 'TIMEOUT_REACHED',
  NOT_FOUND = 'NOT_FOUND',
  CANCELLATION_IN_PROGRESS = 'CANCELLATION_IN_PROGRESS',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
}

export enum DriverDocumentType {
  PASSPORT = 'PASSPORT',
  LICENSE = 'LICENSE',
  REGISTRATION = 'REGISTRATION',
}

export enum DriverStatus {
  CHECKING = 'CHECKING',
  REJECTED = 'REJECTED',
  CREATED = 'CREATED',
  CHECKS_STARTING = 'CHECKS_STARTING',
  REJECT_IN_PROGRESS = 'REJECT_IN_PROGRESS',
  APPROVE_IN_PROGRESS = 'APPROVE_IN_PROGRESS',
  APPROVED = 'APPROVED',
  CHECKS_FINISHED = 'CHECKS_FINISHED',
  CHECKS_CANCELLED = 'CHECKS_CANCELLED',
  SENDING_TO_COMANDIR = 'SENDING_TO_COMANDIR',
  SENT_TO_COMANDIR = 'SENT_TO_COMANDIR',
  CHECKS_CANCELLATION_REQUESTED = 'CHECKS_CANCELLATION_REQUESTED',
  ONBOARDING_STARTING = 'ONBOARDING_STARTING',
  ONBOARDING_STARTED = 'ONBOARDING_STARTED',
  REJECT_REQUESTED = 'REJECT_REQUESTED',
  APPROVE_REQUESTED = 'APPROVE_REQUESTED',
  CHECKS_RETRY_REQUESTED = 'CHECKS_RETRY_REQUESTED',
  ADDITIONAL_APPROVAL_REQUESTED = 'ADDITIONAL_APPROVAL_REQUESTED',
  WORKS = 'WORKS',
  FIRED = 'FIRED',
}

export enum CarStatusEnum {
  CREATED = 'CREATED',
  TRANSFER = 'TRANSFER',
  AT_WORK = 'AT_WORK',
  UNDER_REPAIR = 'UNDER_REPAIR',
  FREE = 'FREE',
  SUB_RENT = 'SUB_RENT',
  BOOKED_FOR_RENT = 'BOOKED_FOR_RENT',
  FOR_SALE = 'FOR_SALE',
  TOTALED = 'TOTALED',
  ILLIQUID = 'ILLIQUID',
  SOLD = 'SOLD',
  INTERNAL_USING = 'INTERNAL_USING',
  VENDED = 'VENDED',
}

export enum CarPropertyTypeEnum {
  LEASING = 'LEASING',
  LEASEBACK = 'LEASEBACK',
  PROPERTY = 'PROPERTY',
}

export enum CarUnderRepairSubStatusEnum {
  // ACCIDENT = 'ACCIDENT',
  // BODY_REPAIR = 'BODY_REPAIR',
  METALWORK_REPAIR = 'METALWORK_REPAIR',
  VEHICLE_ON_THE_PENALTY_AREA = 'VEHICLE_ON_THE_PENALTY_AREA',
  HEAVY_REPAIR = 'HEAVY_REPAIR',
  LOSS_OF_DOCUMENTS = 'LOSS_OF_DOCUMENTS',
  LOSS_OF_KEYS = 'LOSS_OF_KEYS',
  HOUSEHOLD_WORK = 'HOUSEHOLD_WORK',
  GUARANTEE = 'GUARANTEE',
  DETAILING = 'DETAILING',
  BODY_REPAIR_LIGHT = 'BODY_REPAIR_LIGHT',
  BODY_REPAIR_MEDIUM = 'BODY_REPAIR_MEDIUM',
  BODY_REPAIR_HEAVY = 'BODY_REPAIR_HEAVY',
  CHECK_BEFORE_REPAIR = 'CHECK_BEFORE_REPAIR',
}

export enum CarAtWorkSubStatusEnum {
  CONFISCATION = 'CONFISCATION',
  BREAKING = 'BREAKING',
  TAXISHARING = 'TAXISHARING',
  DETAILING = 'DETAILING',
  EMPLOYEE = 'EMPLOYEE',
  TRANSFER = 'TRANSFER',
  DOCUMENT_INVALID = 'DOCUMENT_INVALID',
}

export enum TransmissionEnum {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}

export enum FuelTypeEnum {
  AI100 = 'AI100',
  AI92 = 'AI92',
  AI95 = 'AI95',
  AI98 = 'AI98',
  DIESEL = 'DIESEL',
  METHANE = 'METHANE',
  PROPANE = 'PROPANE',
  ELECTRICITY = 'ELECTRICITY',
}

export enum WorkRuleStatusEnum {
  CREATED = 'CREATED',
  ARCHIVED = 'ARCHIVED',
}

export enum AggregatorCodeEnum {
  YANDEX = 'yandex',
}

export enum RentalContractWorkScheduleEnum {
  FULL_WEEK = '7/0',
  SIX_THROUGH_ONE = '6/1',
}

export enum DayEnum {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export enum RentalContractStatusEnum {
  ACTIVE = 'ACTIVE',
  TERMINATED = 'TERMINATED',
  SUSPENDED = 'SUSPENDED',
  SIGNING = 'SIGNING',
  SIGNING_CANCELLED = 'SIGNING_CANCELLED',
  SIGNED = 'SIGNED',
}

export enum CarDocumentType {
  KASKO_DOCUMENT = 'KASKO_DOCUMENT',
  OSAGO_DOCUMENT = 'OSAGO_DOCUMENT',
  OSGOP_DOCUMENT = 'OSGOP_DOCUMENT',
  PTS_DOCUMENT = 'PTS_DOCUMENT',
  STS_DOCUMENT = 'STS_DOCUMENT',
  TAXI_LICENSES_DOCUMENT = 'TAXI_LICENSES_DOCUMENT',
  DIAGNOSTIC_CARDS_DOCUMENT = 'DIAGNOSTIC_CARDS_DOCUMENT',
  OTHER_DOCUMENT = 'OTHER_DOCUMENT',
}

export enum TransactionMoveFundsTypeEnum {
  COMING = 'COMING',
  SPENDING = 'SPENDING',
}

export enum TransactionPaymentTypeEnum {
  CASH = 'CASH',
  NON_CASH = 'NON_CASH',
}

export enum TransactionBalanceTypeEnum {
  MAIN = 'MAIN',
  BY_DEPT = 'BY_DEPT',
  // BY_DEPOSIT = 'BY_DEPOSIT',
}

export enum WeekDaysEnum {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum AppealsStatusEnum {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  SOLVED = 'SOLVED',
  REJECTED = 'REJECTED',
}

export enum CounterpartiesTypeEnum {
  INSURANCE = 'INSURANCE',
  LEASING = 'LEASING',
  CAR_SERVICE = 'CAR_SERVICE',
}

export enum CarEquipmentTypeEnum {
  EXTERIOR = 'EXTERIOR',
  INTERIOR = 'INTERIOR',
  TRUNK = 'TRUNK',
}

export enum EventAuditEnum {
  CREATED = 'created',
  UPDATED = 'updated',
  DELETED = 'deleted',
  RESTORED = 'restored',
  BALANCE_TRANSFER = 'balance_transfer',
}

export enum CarLicenseeEmployeeTypeEnum {
  controller = 'controller',
  dispatcher = 'dispatcher',
  doctor = 'doctor',
}
