import { CarLicenseeEmployee } from '~/shared/api'
import { Option } from '~/shared/config/constants'
import { EmployeesForm } from './ui/EditForm/model'

export const groupedEmployeesByRegion = (
  carLicenseeEmployees?: CarLicenseeEmployee[],
) => {
  const mappedEmployees = (carLicenseeEmployees || []).reduce(
    (
      acc: Record<UniqueId, { region: Option } & { employees: EmployeesForm }>,
      employee,
    ) => {
      const region = employee?.getRegion().getOption()
      const employeeId = employee.getApiId() as UniqueId
      const fullName = employee.getFullName()
      const employeeType = employee.getEmployeeType()

      return {
        ...acc,
        [region.id]: {
          region: region,
          employees: {
            ...acc[region.id]?.employees,
            [employeeId]: { fullName, employeeType },
          },
        },
      }
    },
    {},
  )
  return Object.entries(mappedEmployees || {}).map(([, values]) => values)
}
