import { CarLicenseeEmployeeTypeEnum } from '~/shared/config/enums'
import { EmployeesForm } from './model'

const mapSort = [
  CarLicenseeEmployeeTypeEnum.dispatcher,
  CarLicenseeEmployeeTypeEnum.controller,
  CarLicenseeEmployeeTypeEnum.doctor,
]

export const sortEmployees = (employees: EmployeesForm) => {
  return Object.entries(employees)
    .sort(
      ([, employeeA], [, employeeB]) =>
        mapSort.indexOf(employeeA.employeeType) -
        mapSort.indexOf(employeeB.employeeType),
    )
    .map(([id, data]) => ({ id, ...data }))
}

export const mappedDefaultValues = (employees: EmployeesForm) =>
  Object.fromEntries(
    Object.entries(employees).map(([id, data]) => [id, data.fullName]),
  )

export const getTextInputProps = (type: CarLicenseeEmployeeTypeEnum) => {
  switch (type) {
    case CarLicenseeEmployeeTypeEnum.dispatcher:
      return { label: 'Диспетчер', placeholder: 'Введите ФИО диспетчера' }
    case CarLicenseeEmployeeTypeEnum.controller:
      return { label: 'Контроллёр', placeholder: 'Введите ФИО контроллёра' }
    case CarLicenseeEmployeeTypeEnum.doctor:
      return { label: 'Медик', placeholder: 'Введите ФИО медика' }
  }
}
