import { KeyboardArrowDown } from '@mui/icons-material'
import { SyntheticEvent } from 'react'
import { myPalette } from '~/shared/lib/theme'
import { SimplePagination } from './SimplePagination'
import {
  Wrapper,
  Pagination,
  RowsPerPage,
  RowsPerPageText,
  Select,
  Option,
} from './styled'

type FooterProps = {
  isRowsCount: boolean
  total: number
  pageSize: number
  page: number
  onPageChange: (page: number) => void
  onPageSizeChange: (pageSize: number) => void
  isSimplePagination?: boolean
}

export function Footer({
  isRowsCount,
  total,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  isSimplePagination,
}: FooterProps) {
  const handlePageSizeChange = (
    _: SyntheticEvent | null,
    value: number | null,
  ) => {
    onPageChange(1)
    onPageSizeChange(value as number)
  }

  return (
    <Wrapper>
      <RowsPerPage>
        <RowsPerPageText>Показывать</RowsPerPageText>

        <Select
          onChange={handlePageSizeChange}
          indicator={<KeyboardArrowDown />}
          value={pageSize}
          defaultValue={pageSize}
          slotProps={{
            listbox: {
              sx: {
                border: 'none',
                borderRadius: '30px',
                padding: '12px',

                'li:hover:not([aria-selected="true"]': {
                  backgroundColor: `${myPalette.gray['50']} !important`,
                },
              },
            },
          }}
        >
          {[10, 25, 50, 100].map((value) => (
            <Option key={value} value={value}>
              {value}
            </Option>
          ))}
        </Select>

        {!isSimplePagination && <RowsPerPageText>из {total}</RowsPerPageText>}
      </RowsPerPage>

      {isSimplePagination ? (
        <SimplePagination
          isRowsCount={isRowsCount}
          page={page}
          onPageChange={onPageChange}
        />
      ) : (
        <Pagination
          count={Math.ceil(total / pageSize)}
          page={page}
          variant='outlined'
          onChange={(_, page: number) => onPageChange(page)}
        />
      )}
    </Wrapper>
  )
}
