import { zodResolver } from '@hookform/resolvers/zod'
import { Stack } from '@mui/joy'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { z } from 'zod'
import { AggregatorDriver } from '~/shared/api'
import { uuidOptionSchema } from '~/shared/lib/schemas'
import { myPalette } from '~/shared/lib/theme'
import { Button } from '~/shared/ui/Buttons'
import { AsyncAutocompleteInput, FormProvider } from '~/shared/ui/Form'
import { ArrowCircleBrokenDownIcon } from '~/shared/ui/Icons'

const formSchema = z.object({
  aggregatorDriver: uuidOptionSchema,
})

export type FormValues = {
  aggregatorDriver: UniqueId
}

type FormProps = {
  onCancel: () => void
  onSuccess: (formValues: FormValues) => void
  isSubmitting: boolean
}

export function Form({ onCancel, onSuccess, isSubmitting }: FormProps) {
  const params = useParams<{ driverId: string }>()
  const driverId = params.driverId as string
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  })

  return (
    <FormProvider form={form} onSuccess={onSuccess}>
      <AsyncAutocompleteInput
        label='Аккаунт'
        placeholder='Выберите аккаунт'
        name='aggregatorDriver'
        fetchOptions={() => AggregatorDriver.fetchOptionsByDriverId(driverId)}
        readOnly={isSubmitting}
      />

      <br />
      <Stack direction='row' justifyContent='space-between' spacing={2}>
        <Button
          variant='gray'
          onClick={onCancel}
          disabled={isSubmitting}
          fullWidth
        >
          Отмена
        </Button>
        <Button
          variant='brand'
          type='submit'
          loading={isSubmitting}
          fullWidth
          startDecorator={
            <ArrowCircleBrokenDownIcon color={myPalette.white.main} />
          }
        >
          Выбрать
        </Button>
      </Stack>
    </FormProvider>
  )
}
