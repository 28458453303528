import { Box } from '@mui/joy'
import { useStore } from 'effector-react'
import { Button } from '~/shared/ui/Buttons'
import { PlusIcon } from '~/shared/ui/Icons'
import { Modal, useModal } from '~/shared/ui/Modal'
import { createFx, formSubmittedCreate } from '../../model'
import { Form } from './Form'
import { FormValues } from './model'

type AddModalProps = {
  excludeRegions: string
}

export function AddModal({ excludeRegions }: AddModalProps) {
  const { isModalShow, closeModal, openModal } = useModal()

  const isSubmitting = useStore(createFx.pending)
  const handleOnSuccess = (formValues: FormValues) => {
    formSubmittedCreate({ formValues, closeModal })
  }

  return (
    <>
      <Button onClick={openModal} startDecorator={<PlusIcon />}>
        Добавить
      </Button>

      <Modal
        title='Добавление группы сотрудников'
        isShow={isModalShow}
        onCancel={closeModal}
        dialogProps={{
          sx: {
            minWidth: '600px',
            maxWidth: '600px',
          },
        }}
      >
        <Box sx={{ padding: '4px' }}>
          <Form
            onSuccess={handleOnSuccess}
            isSubmitting={isSubmitting}
            excludeRegions={excludeRegions}
          />
        </Box>
      </Modal>
    </>
  )
}
