import { zodResolver } from '@hookform/resolvers/zod'
import { Grid, Stack } from '@mui/joy'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { z } from 'zod'
import { AggregatorDriver } from '~/shared/api'
import { uuidOptionSchema } from '~/shared/lib/schemas'
import { Button } from '~/shared/ui/Buttons'
import { AsyncAutocompleteInput, FormProvider } from '~/shared/ui/Form'
import { CreditCardRefreshIcon } from '~/shared/ui/Icons'

const formSchema = z.object({
  fromAggregatorProfileId: uuidOptionSchema,
  toAggregatorProfileId: uuidOptionSchema,
})

export type FormValues = {
  fromAggregatorProfileId: UniqueId
  toAggregatorProfileId: UniqueId
}

type FormProps = {
  onCancel: () => void
  onSuccess: (formValues: FormValues) => void
  isSubmitting: boolean
}

export function Form({ onCancel, onSuccess, isSubmitting }: FormProps) {
  const params = useParams<{ driverId: string }>()
  const driverId = params.driverId as string
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  })

  return (
    <FormProvider form={form} onSuccess={onSuccess}>
      <Grid container spacing={2.5}>
        <Grid xs={12}>
          <AsyncAutocompleteInput
            label='Откуда'
            placeholder='Выберите аккаунт'
            name='fromAggregatorProfileId'
            fetchOptions={() =>
              AggregatorDriver.fetchOptionsByDriverId(driverId)
            }
            readOnly={isSubmitting}
          />
        </Grid>
        <Grid xs={12}>
          <AsyncAutocompleteInput
            label='Куда'
            placeholder='Выберите аккаунт'
            name='toAggregatorProfileId'
            fetchOptions={() =>
              AggregatorDriver.fetchOptionsByDriverId(driverId)
            }
            readOnly={isSubmitting}
          />
        </Grid>
      </Grid>

      <br />
      <Stack direction='row' justifyContent='space-between' spacing={2}>
        <Button
          variant='gray'
          onClick={onCancel}
          disabled={isSubmitting}
          fullWidth
        >
          Отмена
        </Button>
        <Button
          variant='green'
          type='submit'
          loading={isSubmitting}
          fullWidth
          startDecorator={<CreditCardRefreshIcon />}
        >
          Перенести
        </Button>
      </Stack>
    </FormProvider>
  )
}
