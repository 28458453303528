import { createDomain, sample } from 'effector'
import { carLicenseeModel } from '~/entities/Organizations/CarLicensee'
import { CarLicensee, CarLicenseeEmployee } from '~/shared/api'
import { AxiosErrorType } from '~/shared/api'
import { CarLicenseeEmployeeTypeEnum } from '~/shared/config/enums'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'
import { editCarLicenseeModel } from '../EditCarLicensee'
import { addFormModel } from './ui/AddModal'
import { editFormModel } from './ui/EditForm'

const domain = createDomain(
  'features.organizations.carLicensees.employeesCarLicensee',
)

// Create
export const formSubmittedCreate = domain.createEvent<{
  formValues: addFormModel.FormValues
  closeModal: () => void
}>()

export const createFx = domain.createEffect<
  {
    carLicenseeId: UniqueId
    formValues: addFormModel.FormValues
    closeModal: () => void
  },
  CarLicensee,
  AxiosErrorType
>({
  async handler({ carLicenseeId, formValues, closeModal }) {
    const { regionId, controller, dispatcher, doctor } = formValues

    await Promise.all(
      [
        {
          employeeType: CarLicenseeEmployeeTypeEnum.controller,
          fullName: controller,
        },
        {
          employeeType: CarLicenseeEmployeeTypeEnum.dispatcher,
          fullName: dispatcher,
        },
        {
          employeeType: CarLicenseeEmployeeTypeEnum.doctor,
          fullName: doctor,
        },
      ].map((value) => {
        const employee = new CarLicenseeEmployee(value)

        if (isString(regionId)) employee.setRegion(regionId)
        employee.setCarLicensee(carLicenseeId)

        return employee.save()
      }),
    )

    const res = await carLicenseeModel.fetchCarLicenseeWithRelations(
      carLicenseeId,
    )
    closeModal()

    return res
  },
})

sample({
  clock: formSubmittedCreate,
  source: editCarLicenseeModel.$carLicenseeId,
  filter: isString,
  fn: (carLicenseeId, entity) => ({ carLicenseeId, ...entity }),
  target: createFx,
})

sample({
  clock: createFx.doneData,
  fn() {
    return {
      message: 'Сотрудники успешно созданы',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: createFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

// Edit
export const formSubmittedUpdate = domain.createEvent<{
  formValues: editFormModel.FormValues
  regionId: UniqueId
}>()

export const updateFx = domain.createEffect<
  {
    formValues: editFormModel.FormValues
    regionId: UniqueId
    carLicenseeId: UniqueId
  },
  { regionId: UniqueId; carLicensee: CarLicensee },
  AxiosErrorType
>({
  async handler({ formValues, regionId, carLicenseeId }) {
    await Promise.all(
      Object.entries(formValues).map(([id, value]) => {
        const employee = new CarLicenseeEmployee({ fullName: value }, id)
        return employee.save()
      }),
    )

    const carLicensee = await carLicenseeModel.fetchCarLicenseeWithRelations(
      carLicenseeId,
    )
    return { regionId, carLicensee }
  },
})

carLicenseeModel.$carLicenseesCache
  .on(createFx.doneData, (cache, carLicensee) =>
    carLicenseeModel.updateCache(cache, [carLicensee], true),
  )
  .on(updateFx.doneData, (cache, { carLicensee }) =>
    carLicenseeModel.updateCache(cache, [carLicensee], true),
  )

sample({
  clock: formSubmittedUpdate,
  source: editCarLicenseeModel.$carLicenseeId,
  filter: isString,
  fn: (carLicenseeId, entity) => ({ carLicenseeId, ...entity }),
  target: updateFx,
})

export const $pendingUpdate = domain
  .createStore<UniqueId[]>([])
  .on(formSubmittedUpdate, (state, { regionId }) => [...state, regionId])
  .on([updateFx.done, updateFx.fail], (state, { params }) =>
    state.filter((stateFileId) => stateFileId !== params.regionId),
  )

sample({
  clock: updateFx.doneData,
  fn() {
    return {
      message: 'Сотрудники успешно отредактированы',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: updateFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
