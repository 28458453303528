import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { rentalContractModel } from '~/entities/RentalContract'
import { usePermissions } from '~/entities/viewer'
import { myPalette } from '~/shared/lib/theme'
import { Button, IconButton } from '~/shared/ui/Buttons'
import { CheckFileIcon } from '~/shared/ui/Icons'
import { useModal } from '~/shared/ui/Modal'
import { ActiveModal } from './ActiveModal'

type RentalContractActiveProps = {
  rentalContractId: UniqueId
  isButton?: boolean
  isIcon?: boolean
  title?: string
  modalTitle?: string
  modalButtonTitle?: string
}

export const RentalContractActive = ({
  rentalContractId,
  isButton,
  isIcon,
  title = 'Активировать договор',
  modalTitle,
  modalButtonTitle,
}: RentalContractActiveProps) => {
  const { canRentalContractsCreate, canRentalContractsUpdate } =
    usePermissions()

  const { isModalShow, closeModal, openModal } = useModal()
  const queryClient = useQueryClient()

  const handleSuspend = useCallback(async () => {
    await rentalContractModel.activeRentalContractFx(rentalContractId)
    await queryClient.invalidateQueries({
      queryKey: ['rental-contracts-list'],
    })
  }, [queryClient, rentalContractId])

  if (!canRentalContractsCreate && !canRentalContractsUpdate) return null

  return (
    <>
      <ActiveModal
        onOk={handleSuspend}
        onCancel={closeModal}
        isShow={isModalShow}
        title={modalTitle}
        buttonTitle={modalButtonTitle}
      />
      {isButton && (
        <Button
          variant='brand'
          startDecorator={<CheckFileIcon />}
          onClick={openModal}
        >
          {title}
        </Button>
      )}
      {isIcon && (
        <IconButton tooltipProps={{ title }} onClick={openModal}>
          <CheckFileIcon color={myPalette.brand['700']} />
        </IconButton>
      )}
    </>
  )
}
