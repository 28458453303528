import { zodResolver } from '@hookform/resolvers/zod'
import { Grid } from '@mui/joy'
import { useGate, useStore } from 'effector-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { CarFormGate } from '~/entities/Car/ui/Form/model'
import { useValidateForm } from '~/entities/Car/ui/Form/useValidateForm'
import { FormButtons, formButtonsModel, FormProvider } from '~/shared/ui/Form'
import { AdditionalInformation } from './AdditionalInformation'
import { Main } from './Main'
import { FormValues, formSchema } from './model'
import { Specifications } from './Specifications'

export type FormProps = {
  isCreating?: boolean
  canEdit?: boolean
  isSubmitting?: boolean
  isLoading?: boolean
  onSuccess?: (formValues: FormValues) => void
  defaultValues?: Partial<FormValues>
  carId?: UniqueId | null
  isView?: boolean
}

export function Form({
  isCreating,
  canEdit,
  isSubmitting,
  isLoading,
  defaultValues,
  onSuccess,
  carId,
  isView,
}: FormProps) {
  const isEditing = useStore(formButtonsModel.$isEditing)
  const disabled = (!isEditing && !isCreating) || isSubmitting

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  useGate(CarFormGate, { carId })

  const { isValidatePending, isValidateError } = useValidateForm(form)

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues, isEditing])

  return (
    <FormProvider form={form} onSuccess={onSuccess} canEdit={canEdit}>
      <Main
        disabled={disabled}
        showSkeleton={isLoading}
        isEditing={isEditing || isCreating}
        isView={isView}
      />
      <Grid container spacing={2}>
        <Grid xs={6}>
          <Specifications disabled={disabled} showSkeleton={isLoading} />
        </Grid>
        <Grid xs={6} display='flex'>
          <AdditionalInformation disabled={disabled} showSkeleton={isLoading} />
        </Grid>
      </Grid>
      <FormButtons
        loading={isSubmitting || isValidatePending}
        isCreating={isCreating}
        disabled={isValidateError}
      />
    </FormProvider>
  )
}
