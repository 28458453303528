import { zodResolver } from '@hookform/resolvers/zod'
import { Stack } from '@mui/joy'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { uuidOptionsSchema } from '~/shared/lib/schemas'
import { myPalette } from '~/shared/lib/theme'
import { Button } from '~/shared/ui/Buttons'
import { AutocompleteInput, FormProvider } from '~/shared/ui/Form'
import { SlashCircleIcon } from '~/shared/ui/Icons'
import { reasonsTerminationsOptions } from './constant'

const formSchema = z.object({
  reasons: uuidOptionsSchema.min(1, 'Обязательное поле'),
})

export type FormValues = {
  reasons: string[]
}

type FormProps = {
  onSuccess: (formValues: FormValues) => void
  onCancel: () => void
  isSubmitting: boolean
  buttonSuccessTitle?: string
}

export function Form({
  onSuccess,
  onCancel,
  isSubmitting,
  buttonSuccessTitle,
}: FormProps) {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  })
  return (
    <FormProvider form={form} onSuccess={onSuccess}>
      <AutocompleteInput
        label='Причины'
        placeholder='Выберите причину расторжения договора'
        name='reasons'
        options={reasonsTerminationsOptions}
        multiple
        autocompleteXs={12}
      />
      <br />
      <Stack direction='row' justifyContent='space-between' spacing={2}>
        <Button
          variant='gray'
          onClick={onCancel}
          disabled={isSubmitting}
          fullWidth
        >
          Отмена
        </Button>
        <Button
          variant='red'
          type='submit'
          loading={isSubmitting}
          fullWidth
          startDecorator={<SlashCircleIcon color={myPalette.white.main} />}
        >
          {buttonSuccessTitle || 'Расторгнуть'}
        </Button>
      </Stack>
    </FormProvider>
  )
}
