import { styled } from '@mui/joy'
import { myPalette } from '~/shared/lib/theme'

export const CustomPagination = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 6px;
`

export const CustomPaginationPage = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  height: 44px;
  border-radius: 30px;
  border: 1px solid ${myPalette.brand['100']};
`
