import { z } from 'zod'
import { CarLicensee } from '~/shared/api/carLicensee'
import { Region } from '~/shared/api/region'
import { CarLicenseeEmployeeTypeEnum } from '~/shared/config/enums'
import { dateSchema, uuidOptionSchema } from '~/shared/lib/schemas'
import { ApiModel, ToOneRelation } from './core'

const attributesSchema = z.object({
  fullName: z.string().trim().min(1, 'Обязательное поле'),
  employeeType: z.nativeEnum(CarLicenseeEmployeeTypeEnum),
})

const relationsSchema = z.object({
  regionId: uuidOptionSchema,
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributesSchema)
  .merge(relationsSchema)

export type СarLicenseeEmployeeAttributes = z.infer<typeof schema>

export class CarLicenseeEmployee extends ApiModel<typeof schema> {
  static jsonApiType = 'car-licensee-employees'

  static schema = schema

  getFullName(): string {
    return this.getAttribute('fullName')
  }
  getEmployeeType(): CarLicenseeEmployeeTypeEnum {
    return this.getAttribute('employeeType')
  }

  region(): ToOneRelation<Region, this> {
    return this.hasOne(Region)
  }
  getRegion(): Region {
    return this.getRelation('region')
  }
  setRegion(id: UniqueId) {
    const region = new Region()
    region.setApiId(id)
    this.setRelation('region', region)
  }

  setCarLicensee(id: UniqueId) {
    const carLicensee = new CarLicensee()
    carLicensee.setApiId(id)
    this.setRelation('carLicensee', carLicensee)
  }
}
