import { LinearProgress, Stack } from '@mui/joy'
import { useStoreMap, useStore } from 'effector-react'
import { useMemo } from 'react'
import { Element } from 'react-scroll'
import { carLicenseeModel } from '~/entities/Organizations/CarLicensee'
import { usePermissions } from '~/entities/viewer'
import { Container, ContainerTitle, PageTitle } from '~/shared/ui/Layout'
import { QuickNavigation } from '~/shared/ui/QuickNavigation'
import { editCarLicenseeModel } from '../EditCarLicensee'
import { groupedEmployeesByRegion } from './helpers'
import { $pendingUpdate, formSubmittedUpdate } from './model'
import { AddModal } from './ui/AddModal'
import { EditForm } from './ui/EditForm'

export function EmployeesCarLicensee() {
  const { canCarOrganizationsUpdate } = usePermissions()

  const isLoading = useStore(carLicenseeModel.requestFx.pending)
  const submittingByRegionIds = useStore($pendingUpdate)

  const carLicenseeEmployees = useStoreMap(
    editCarLicenseeModel.$carLicensee,
    (carLicensee) => carLicensee?.getCarLicenseeEmployees(),
  )

  const groupedEmployees = useMemo(
    () => groupedEmployeesByRegion(carLicenseeEmployees),
    [carLicenseeEmployees],
  )

  const quickNavigationOptions = groupedEmployees.map(({ region }) => ({
    label: region.label,
    anchor: region.id,
  }))

  const excludeRegions = useMemo(
    () => groupedEmployees.map(({ region }) => region.id).join(','),
    [groupedEmployees],
  )

  return (
    <>
      <Container>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          mb={2}
        >
          <PageTitle>Сотрудники</PageTitle>

          {canCarOrganizationsUpdate && (
            <AddModal excludeRegions={excludeRegions} />
          )}
        </Stack>
      </Container>

      {isLoading && !groupedEmployees?.length && (
        <Container>
          <LinearProgress size='lg' />
        </Container>
      )}

      {!isLoading && !groupedEmployees?.length && (
        <Container>
          <ContainerTitle>Сотрудники еще не добавлены</ContainerTitle>
        </Container>
      )}

      {groupedEmployees.map(({ region, employees }) => {
        return (
          <Element key={region.id} name={region.id}>
            <Container>
              <ContainerTitle>{region.label}</ContainerTitle>
              <EditForm
                employees={employees}
                onSuccess={(formValues) =>
                  formSubmittedUpdate({ formValues, regionId: region.id })
                }
                isSubmitting={submittingByRegionIds.some(
                  (item) => item === region.id,
                )}
                canEdit={canCarOrganizationsUpdate}
              />
            </Container>
          </Element>
        )
      })}

      {!!quickNavigationOptions?.length && (
        <QuickNavigation options={quickNavigationOptions} />
      )}
    </>
  )
}
