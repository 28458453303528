import * as z from 'zod'
import { CarLicenseeEmployeeTypeEnum } from '~/shared/config/enums'

export const formSchema = z.record(z.string())

export type TypeFormSchema = z.infer<typeof formSchema>

export type FormValues = TypeFormSchema

export type EmployeesForm = Record<
  UniqueId,
  {
    fullName: string
    employeeType: CarLicenseeEmployeeTypeEnum
  }
>
