import { createDomain, sample, attach } from 'effector'
import { interval } from 'patronum'
import { rentalContractCreateModel } from '~/features/RentalContracts/CreateRentalContract'
import { FormValues, driverModel } from 'src/entities/Driver'
import { rentalContractModel } from '~/entities/RentalContract'
import { Driver, DriverAttributes } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'
import { formButtonsModel } from '~/shared/ui/Form'

export const domain = createDomain('features.driver.edit')

sample({
  clock: driverModel.$id,
  filter: isString,
  target: driverModel.requestDriverFx,
})

export const sendFormFx = attach({
  effect: driverModel.saveFx,
  source: driverModel.$id,
  mapParams: (values: FormValues, id) => {
    const { regionId, citizenship, drivingLicenseIssuingCountry, ...attr } =
      values
    const driver = new Driver({
      ...(isString(citizenship) && { citizenship }),
      ...(isString(drivingLicenseIssuingCountry) && {
        drivingLicenseIssuingCountry,
      }),
      ...attr,
    })
    driver.setApiId(id as string)

    if (isString(regionId)) driver.setRegion(regionId)

    return driver
  },
})
sample({
  clock: sendFormFx.doneData,
  fn() {
    return {
      message: 'Сохранено успешно',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, formButtonsModel.editingEnded],
})
sample({
  clock: sendFormFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const formSubmitted = domain.createEvent<FormValues>()
sample({
  clock: formSubmitted,
  target: sendFormFx,
})

/* Update status */
const intervalStarted = domain.createEvent()
const intervalStopped = domain.createEvent()
const { tick } = interval({
  timeout: 7000,
  start: intervalStarted,
  stop: intervalStopped,
  leading: false,
  trailing: false,
})
const requestStatusFx = domain.createEffect<string, Driver>({
  async handler(id) {
    const response = await Driver.option('fields[drivers]', 'status').find(id)
    const driver = response.getData()
    if (!driver) throw new Error('Driver not found')
    return driver
  },
})
sample({
  clock: tick,
  source: driverModel.$id,
  filter: isString,
  target: requestStatusFx,
})
sample({
  clock: driverModel.Gate.open,
  target: intervalStarted,
})
sample({
  clock: driverModel.Gate.close,
  target: intervalStopped,
})
sample({
  clock: driverModel.$driver,
  filter(driver) {
    const status = driver?.getStatus() ?? ''
    return ['APPROVED', 'REJECTED'].includes(status)
  },
  target: intervalStopped,
})
sample({
  clock: requestStatusFx.doneData,
  fn(driver) {
    return {
      id: driver.getApiId() as string,
      status: driver.getStatus() as DriverAttributes['status'],
    }
  },
  target: driverModel.driverStatusUpdated,
})

// CheckIsFirstRentalContract
sample({
  clock: rentalContractModel.checkIsFirstRentalContract,
  source: driverModel.$id,
  filter: isString,
  target: rentalContractModel.checkIsFirstRentalContractFx,
})

// RentalContract
sample({
  clock: [
    rentalContractCreateModel.createFx.doneData,
    rentalContractModel.terminateRentalContractFx.doneData,
    rentalContractModel.activeRentalContractFx.doneData,
    rentalContractModel.canceledRentalContractFx.doneData,
  ],
  source: driverModel.$id,
  filter: isString,
  target: driverModel.requestDriverSilentFx,
})
