import { GridColDef } from '@mui/x-data-grid'
import { ColumnChipList, ColumnLink } from '~/shared/ui/AsyncGrid'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Наименование',
    field: 'name',
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => (
      <ColumnLink to={`${row.id}/details`}>{row.name}</ColumnLink>
    ),
  },
  {
    headerName: 'Номер телефона',
    field: 'phoneNumber',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Диспетчер',
    field: 'dispatchers',
    sortable: false,
    flex: 1,
    renderCell: ({ value }) => <ColumnChipList items={value} />,
  },
  {
    headerName: 'Контроллёр',
    field: 'controllers',
    sortable: false,
    flex: 1,
    renderCell: ({ value }) => <ColumnChipList items={value} />,
  },
  {
    headerName: 'Медик',
    field: 'doctors',
    flex: 1,
    renderCell: ({ value }) => <ColumnChipList items={value} />,
  },
]
