import { Box, Tooltip } from '@mui/joy'
import { GridCellProps, GridCell as MuiGridCell } from '@mui/x-data-grid'
import { useMemo, useRef } from 'react'
import {
  CellWrapper,
  HiddenCellWrapper,
} from '~/shared/ui/AsyncGrid/GridCell/styled'

export function GridCell(props: GridCellProps) {
  const ref = useRef<HTMLElement | null>()

  const isTooltipVisible =
    (ref?.current?.offsetWidth || 0) < (ref?.current?.scrollWidth || 0)

  const renderValue = useMemo(
    () => props.children || <span>{props.value}</span>,
    [props.children, props.value],
  )

  return (
    <MuiGridCell {...props}>
      <Box sx={{ width: '100%', position: 'relative' }}>
        <HiddenCellWrapper ref={ref}>{renderValue}</HiddenCellWrapper>

        <Tooltip
          variant='outlined'
          arrow
          title={
            isTooltipVisible && (
              <Box sx={{ maxWidth: '800px' }}>{renderValue}</Box>
            )
          }
        >
          <CellWrapper
            data-testid={`data-gird-cell-${props.field}`}
            data-cell-element
          >
            {renderValue}
          </CellWrapper>
        </Tooltip>
      </Box>
    </MuiGridCell>
  )
}
