import { Tabs } from '~/shared/ui/RoutesTabs/type'
import { AddCarLicenseePage } from './CarLicensee/AddCarLicenseePage'
import { EditCarLicenseePage } from './CarLicensee/EditCarLicenseePage'
import { ListCarLicenseePage } from './CarLicensee/ListCarLicenseePage'
import { AddCarRentalPage } from './CarRentals/AddCarRentalPage'
import { EditCarRentalPage } from './CarRentals/EditCarRentalPage'
import { ListCarRentalPage } from './CarRentals/ListCarRentalPage'

export const useConfigTabs = (): Tabs => {
  return [
    {
      label: 'Арендодатели',
      path: '/car-rentals',
      content: <ListCarRentalPage />,
      children: [
        {
          path: '/new',
          content: <AddCarRentalPage />,
          full: true,
        },
        {
          path: '/:carRentalId',
          content: <EditCarRentalPage />,
          full: true,
        },
      ],
    },
    {
      label: 'Лицензиаты',
      path: '/car-licensees',
      content: <ListCarLicenseePage />,
      children: [
        {
          path: '/new',
          content: <AddCarLicenseePage />,
          full: true,
        },
        {
          path: '/:carLicenseeId/*',
          content: <EditCarLicenseePage />,
          full: true,
        },
      ],
    },
  ]
}
