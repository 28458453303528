import { Box, Stack } from '@mui/joy'
import { useCallback, useState } from 'react'
import { Alert } from '~/shared/ui/Alerts'
import { Button } from '~/shared/ui/Buttons'
import { CheckFileIcon } from '~/shared/ui/Icons'
import { Modal } from '~/shared/ui/Modal'

type ActiveModalProps = {
  isShow: boolean
  onOk: () => Promise<void>
  onCancel: () => void
  title?: string
  buttonTitle?: string
}

export const ActiveModal = ({
  isShow,
  onOk,
  onCancel,
  title = 'Активировать договор ?',
  buttonTitle = 'Активировать',
}: ActiveModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleSuccess = useCallback(async () => {
    try {
      setIsSubmitting(true)
      await onOk()
      onCancel()
    } finally {
      setIsSubmitting(false)
    }
  }, [onCancel, onOk])

  return (
    <Modal
      isShow={isShow}
      onCancel={isSubmitting ? undefined : onCancel}
      dialogProps={{
        sx: {
          minWidth: '600px',
          maxWidth: '600px',
        },
      }}
    >
      <Alert variant='info' sx={{ margin: '44px 0 24px 0' }}>
        {title}
      </Alert>
      <Box sx={{ padding: '4px' }}>
        <Stack direction='row' justifyContent='space-between' spacing={2}>
          <Button
            variant='gray'
            onClick={onCancel}
            disabled={isSubmitting}
            fullWidth
          >
            Отмена
          </Button>
          <Button
            variant='brand'
            loading={isSubmitting}
            fullWidth
            startDecorator={<CheckFileIcon />}
            onClick={handleSuccess}
          >
            {buttonTitle}
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}
