import { z } from 'zod'
import { Option } from '~/shared/config/constants'
import { uuidOptionSchema } from '~/shared/lib/schemas'

export const formSchema = z.object({
  regionId: uuidOptionSchema,
  dispatcher: z.string().trim().min(1, 'Обязательное поле'),
  controller: z.string().trim().min(1, 'Обязательное поле'),
  doctor: z.string().trim().min(1, 'Обязательное поле'),
})

export type TypeFormSchema = z.infer<typeof formSchema>

export type FormValues = Omit<TypeFormSchema, 'regionId'> & {
  regionId: UniqueId | Option
}
