import { createDomain } from 'effector'
import { useStoreMap } from 'effector-react'
import { AxiosErrorType, CarLicensee } from '~/shared/api'
import { createCache } from '~/shared/lib/mapCacheFactory'

export const domain = createDomain('entities.carLicensee')

export const requestFx = domain.createEffect<UniqueId, CarLicensee>({
  handler: fetchCarLicenseeWithRelations,
})

export const saveFx = domain.createEffect<
  CarLicensee,
  CarLicensee,
  AxiosErrorType
>({
  async handler(carLicensee) {
    await carLicensee.save()
    return fetchCarLicenseeWithRelations(carLicensee.getApiId() as UniqueId)
  },
})

const {
  $cache: $carLicenseesCache,
  useCache: useCarLicenseeCache,
  updateCache,
} = createCache<CarLicensee>({
  domain,
  getEntityId: (carLicensee) => carLicensee.getApiId() as UniqueId,
})

export { $carLicenseesCache, useCarLicenseeCache, updateCache }

$carLicenseesCache
  .on(requestFx.doneData, (cache, carLicensee) =>
    updateCache(cache, [carLicensee]),
  )
  .on(saveFx.doneData, (cache, carLicensee) =>
    updateCache(cache, [carLicensee], true),
  )

export const $carLicenseesError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on([requestFx.fail], (store, { error, params: id }) => ({
    [id]: error,
    ...store,
  }))
export const useCarLicenseeError = (id: UniqueId) =>
  useStoreMap($carLicenseesError, (errors) => errors[id])

export async function fetchCarLicenseeWithRelations(id: UniqueId) {
  const response = await CarLicensee.with('carLicenseeEmployees')
    .with('carLicenseeEmployees.region')
    .find(id)
  return response.getData() as CarLicensee
}
