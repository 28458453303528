import { Grid } from '@mui/joy'
import { AggregatorAccount, CarRental, VehicleCategory } from '~/shared/api'
import { AsyncAutocompleteInput, TextInput } from '~/shared/ui/Form'
import { Container, ContainerTitle } from '~/shared/ui/Layout'

type AdditionalInformationProps = {
  showSkeleton?: boolean
  disabled?: boolean
}

export function AdditionalInformation({
  showSkeleton,
  disabled,
}: AdditionalInformationProps) {
  return (
    <Container>
      <ContainerTitle>Дополнительная информация</ContainerTitle>
      <Grid container spacing={2.5}>
        <Grid xs={12}>
          <AsyncAutocompleteInput
            label='Аккаунт Агрегатора'
            name='aggregatorAccountOption'
            placeholder='Выберите аккаунт агрегатора'
            skeletonShow={showSkeleton}
            readOnly={disabled}
            fetchOptions={AggregatorAccount.fetchOptions}
          />
        </Grid>
        <Grid xs={12}>
          <AsyncAutocompleteInput
            label='Арендодатель'
            name='carRentalId'
            placeholder='Выберите арендодателя'
            skeletonShow={showSkeleton}
            readOnly={disabled}
            fetchOptions={CarRental.fetchOptions}
          />
        </Grid>
        <Grid xs={12}>
          <AsyncAutocompleteInput
            label='Категория ТС'
            name='vehicleCategoryId'
            placeholder='Выберите категорию ТС'
            skeletonShow={showSkeleton}
            readOnly={disabled}
            fetchOptions={VehicleCategory.fetchOptions}
          />
        </Grid>
        <Grid xs={12}>
          <TextInput
            label='Позывной'
            name='callsign'
            placeholder='Позывной'
            skeletonShow={showSkeleton}
            readOnly
          />
        </Grid>
      </Grid>
    </Container>
  )
}
