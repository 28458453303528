import { EmployeesCarLicensee } from 'src/features/Organization/CarLicensees/EmployeesCarLicensee'
import { EditCarLicensee } from '~/features/Organization/CarLicensees/EditCarLicensee'
import { Tabs } from '~/shared/ui/RoutesTabs/type'

export const useConfigTabs = (): Tabs => {
  return [
    {
      label: 'Информация',
      path: '/details',
      content: <EditCarLicensee />,
    },
    {
      label: 'Сотрудники',
      path: '/employees',
      content: <EmployeesCarLicensee />,
    },
  ]
}
